import { createAsyncThunk } from '@reduxjs/toolkit'
import { getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'
import { setCookie } from '../../utils/security/cookies'
import { setAppLoading, setErrorStatus } from '../app/redux'
import { getTokenInfo, roleKey, tokenKey } from './utils'

type LoginType = {
  username: string
  password: string
  personalInformation?: string
  today: Date
}
const INFORMATION_STATUS = 202
const loggingInAdditionnal = 203

export const requestLogin = createAsyncThunk<any, LoginType>(
  'auth/requestLogin',
  async ({ username, password, personalInformation, today }, { rejectWithValue }) => {
    const response: any = await setAxios('/auth/login', {
      username,
      password,
      personalInformation,
    })

    if (response.error) {
      return rejectWithValue({
        error: response.error.response.status,
        errorBody: response.error.response.data,
        isPersonal: !!personalInformation?.length,
      })
    } else {
      setCookie('logged_at', new Date(today).toISOString())
      return {
        needsPersonal: response.status === INFORMATION_STATUS,
        loggingInAdditionnal: response.status === loggingInAdditionnal,
        dataInfo: response.data,
      }
    }
  }
)

export const requestLoginStates = (builder) => {
  builder.addCase(requestLogin.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    requestLogin.fulfilled,
    (state, { payload: { loggingInAdditionnal, needsPersonal, dataInfo } }) => {
      state.error = false
      state.loggingInAdditionnal = false

      if (loggingInAdditionnal) {
        state.isLoading = false
        state.loggingInAdditionnal = true
        return
      }

      if (needsPersonal) {
        state.isLoading = false
        state.needsPersonal = true
        return
      }

      state.needsPersonal = false

      const { access_token } = dataInfo

      // Set on Session Storage
      const data = getTokenInfo(access_token)
      setCookie(roleKey, 'ROLE_' + data.role)
      setCookie(tokenKey, access_token)

      // Saving info
      state.exp = data.exp
      state.isLoading = false
      state.token = data.token
    }
  )

  builder.addCase(requestLogin.rejected, (state, { payload }) => {
    state.error = payload.error || true
    state.errorBody = payload.errorBody
    state.needsPersonal = payload.isPersonal
    state.loggingInAdditionnal = false
    state.isLoading = false
  })
}

export const requestResetPassword = createAsyncThunk<
  any,
  { params: any; callback?: any; token: string }
>(
  'auth/requestResetPassword',
  async ({ params, token, callback }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const body = {
      token,
      messageChannel: params.email ? 'EMAIL' : 'SMS',
      personalInformation: params.personalInformation,
      phoneNumber: params.phoneNumber || undefined,
      phoneCode: params.phoneCode || undefined,
      email: params.email || undefined,
      login: params.username,
      birthDate: new Date(params.birthDate),
    }

    const response: any = await setAxios('/auth/reset-password', body, getTokenHeaders())

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error?.response?.data?.message }))
      return rejectWithValue(response.error.response.status)
    }
    callback?.()
    dispatch(setAppLoading(false))
  }
)
